define("fx/components/warning-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="warning-tooltip">
    {{yield (hash
      heading=(component "blank")
    )}}
  
    <span class="warning-tooltip__trigger">
      {{inline-svg "/images/exclamation-triangle"}}
  
      {{yield (hash
        content=(component "ember-tooltip" side=(if @side @side "top"))
      )}}
    </span>
  </div>
  
  */
  {
    "id": "I0050lFN",
    "block": "[[[10,0],[14,0,\"warning-tooltip\"],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"heading\"],[[50,\"blank\",0,null,null]]]]]],[1,\"\\n\\n  \"],[10,1],[14,0,\"warning-tooltip__trigger\"],[12],[1,\"\\n    \"],[1,[28,[35,3],[\"/images/exclamation-triangle\"],null]],[1,\"\\n\\n    \"],[18,2,[[28,[37,1],null,[[\"content\"],[[50,\"ember-tooltip\",0,null,[[\"side\"],[[52,[30,1],[30,1],\"top\"]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@side\",\"&default\"],false,[\"yield\",\"hash\",\"component\",\"inline-svg\",\"if\"]]",
    "moduleName": "fx/components/warning-tooltip.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});