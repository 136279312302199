define("fx/components/table/date-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <th
    data-value={{@date}}
    ...attributes
  >
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      <div class="date-header">
        <span>
          {{#if @header}}
            {{@header}}
          {{else}}
            {{format-date
              @date
              timeZone="UTC"
              month="long"
              year="numeric"
            }}
          {{/if}}
        </span>
  
        {{#if @warningTooltip}}
          <WarningTooltip as |tooltip|>
            {{#if @warningHeading}}
              <tooltip.heading>
                {{@warningHeading}}
              </tooltip.heading>
            {{/if}}
  
            <tooltip.content>
              {{@warningContent}}
            </tooltip.content>
          </WarningTooltip>
        {{/if}}
      </div>
    {{/if}}
  </th>
  */
  {
    "id": "mDHGXLE6",
    "block": "[[[11,\"th\"],[16,\"data-value\",[30,1]],[17,2],[12],[1,\"\\n\"],[41,[48,[30,8]],[[[1,\"    \"],[18,8,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"date-header\"],[12],[1,\"\\n      \"],[10,1],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"          \"],[1,[30,3]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[35,3],[[30,1]],[[\"timeZone\",\"month\",\"year\"],[\"UTC\",\"long\",\"numeric\"]]]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\\n\"],[41,[30,4],[[[1,\"        \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,6],[[[1,\"            \"],[8,[30,5,[\"heading\"]],null,null,[[\"default\"],[[[[1,\"\\n              \"],[1,[30,6]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n          \"],[8,[30,5,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[1,[30,7]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"]],[5]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@date\",\"&attrs\",\"@header\",\"@warningTooltip\",\"tooltip\",\"@warningHeading\",\"@warningContent\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"format-date\",\"warning-tooltip\"]]",
    "moduleName": "fx/components/table/date-header.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});