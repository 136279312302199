define("fx/components/dynamic/prices-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <DataTable
    @table={{@prices}}
    @valueFormat="currency"
    @headerFormat="month"
    @errors={{@errors}}
    @readonly={{@readonly}}
    class="form__table"
    as |table|
  >
    <table.Caption>
      <div class="table-export__title">
        {{@prices.metadata.contextName}}
      </div>
    </table.Caption>
  </DataTable>
  */
  {
    "id": "0CNtnXfb",
    "block": "[[[8,[39,0],[[24,0,\"form__table\"]],[[\"@table\",\"@valueFormat\",\"@headerFormat\",\"@errors\",\"@readonly\"],[[30,1],\"currency\",\"month\",[30,2],[30,3]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,4,[\"Caption\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"table-export__title\"],[12],[1,\"\\n      \"],[1,[30,1,[\"metadata\",\"contextName\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[4]]]]]],[\"@prices\",\"@errors\",\"@readonly\",\"table\"],false,[\"data-table\"]]",
    "moduleName": "fx/components/dynamic/prices-table.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});